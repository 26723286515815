<template>
    <div>
        <v-card class="mx-auto" elevation="6">
            <h2 class="align-center heading">Package Details</h2>
            <v-card-text>
                <v-form ref="form" lazy-validation class="ma-6">
                    <v-layout wrap>
                        <v-flex sm12>
                            <v-text-field label="Name" v-model="packageItem.name" :rules="titleRules" required />
                        </v-flex>
                        <v-flex sm12>
                            <label>Description</label>
                            <v-textarea v-model="packageItem.desc"></v-textarea>
                            <!-- <wysiwyg v-model="packageItem.desc"></wysiwyg> -->
                        </v-flex>
                        <v-flex sm12>
                            <v-radio-group label="Buying Type" v-model="packageItem.bType" mandatory>
                                <v-radio label="One Time Buy" value="ONE_TIME"></v-radio>
                                <v-radio label="Future Buy" value="FUTURE_BUY"></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex sm12>
                            <h4>Add Package Image</h4><br>
                            <file-upload ref="fileupload" v-model="image" label="Upload Images"
                                :multiple="false"></file-upload>
                        </v-flex>
                        <v-layout wrap v-if="packageItem.image">
                            <v-flex xs12 sm4 md3 class="pa-1" width="200">
                                <video-card :src="packageItem.image.path" @close="$emit('delete')" :showClose="true"
                                    v-if="packageItem.image.mimetype.startsWith('video/')"></video-card>
                                <image-card :src="packageItem.image.path" @close="$emit('delete')" :showClose="true"
                                    v-else></image-card>
                            </v-flex>
                        </v-layout>
                    </v-layout>
                </v-form>
            </v-card-text>
        </v-card>
        <v-flex text-right class="mt-6">
            <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
            <v-btn class="mr-4" :style="theme" @click="submit()">Next</v-btn>
        </v-flex>
    </div>
</template>

<script>
export default {
    props: {
        packageItem: {
            type: Object
        },
    },
    data() {
        return {
            image: null,
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.packageItem.uploadImage = this.image
                this.$emit('add', this.packageItem)
            }
        }
    },
}
</script>

<style scoped>
.align-center {
    text-align: center
}

.heading {
    padding: 2% 0%;
}
</style>
