<template>
<div class="display-block">
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
    <package-form v-if="currentStep==1" @add="addPackage($event)" @delete="removeImage" :packageItem="packageItem" />
    <v-card v-if="currentStep==2">
        <product-selection @selectProduct="showDialog = true" @delete="removeProduct($event)" title="Select Product" btnTitle="Add Product" :products="packageItem.products"></product-selection>
        <product-selection @selectProduct="selectAddOn()" @delete="removeProduct($event)" title="Select Add On" btnTitle="Add Add On Options" :products="packageItem.addOns"></product-selection>
    </v-card>
    <v-flex v-if="currentStep==2" text-right class="mt-6">
        <v-btn class="mr-4" :style="themeInverted" outlined @click="goBack()">Previous</v-btn>
        <v-btn class="mr-4" :style="theme" @click="calculatePrice()">Next</v-btn>
    </v-flex>
    <product-select-pop-up @save="saveProduct($event)" :showDialog="showDialog" @close="showDialog=false" />
    <v-card v-if="currentStep==3">
        <h2 class="align-center heading">Configure Price</h2>
        <v-flex class="mx-12">
            <row-item label="Suggested Price" :value="suggestedPrice" />
            <row-item label="Configure Price" />
            <v-text-field type="number" v-model.number="packageItem.sellingPrice" outlined></v-text-field>
        </v-flex>
    </v-card>
    <v-flex v-if="currentStep==3" text-right class="mt-6">
        <v-btn class="mr-4" :style="themeInverted" outlined @click="goBack()">Previous</v-btn>
        <v-btn class="mr-4" :style="theme" @click="goNext()">Next</v-btn>
    </v-flex>
    <v-card v-if="currentStep==4">
        <h2 class="align-center heading">Review Your Package</h2>
        <v-flex class="mx-12">
            <row-item label="Name" :value="packageItem.name"></row-item>
            <row-item label="Buy Type" :value="packageItem.bType=='ONE_TIME'?'One Time':'Future Buy'"></row-item>
            <row-item label="Required Items"></row-item>
            <v-layout v-if="packageItem.products?.length>0">
                <v-flex sm2 v-for="product in packageItem.products" :key="product._id">
                    <v-chip>{{ product.title }}</v-chip>
                </v-flex>
            </v-layout>
            <row-item v-if="packageItem.addOns?.length>0" label="Add On Items"></row-item>
            <v-layout v-if="packageItem.addOns?.length>0">
                <v-flex sm2 v-for="product in packageItem.addOns" :key="product._id">
                    <v-chip>{{ product.title }}</v-chip>
                </v-flex>
            </v-layout>
            <row-item label="Price" :value="packageItem.sellingPrice"></row-item>
            <row-item label="Selected Images"></row-item>
            <v-layout wrap v-if="packageItem.image">
                <v-flex xs12 sm4 md3 class="pa-1" width="200">
                    <video-card :src="packageItem.image.path" v-if="packageItem.image.path&&packageItem.image.mimetype.startsWith('video/')"></video-card>
                    <image-card :src="packageItem.image.path" v-else-if="packageItem.image.path&&packageItem.image.mimetype.startsWith('image/')"></image-card>
                </v-flex>
            </v-layout>
            <v-flex v-else-if="image">
                <v-chip class="ma-2">{{image.name}}</v-chip>
            </v-flex>
        </v-flex>
    </v-card>
    <v-flex v-if="currentStep==4" text-right class="mt-6">
        <v-btn class="mr-4" :style="themeInverted" outlined @click="goBack()">Previous</v-btn>
        <v-btn class="mr-4" :style="theme" @click="submit()">Save</v-btn>
    </v-flex>
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
</div>
</template>

<script>
import PackageForm from '@/components/PackageForm'
import ProductSelection from '@/components/ProductSelection.vue'
import ProductSelectPopUp from '@/components/ProductSelectPopUp.vue'
import appConstants from '@/utils/appConstants'
import RowItem from '@/components/RowItem.vue'
export default {
    components: {
        PackageForm,
        ProductSelection,
        ProductSelectPopUp,
        RowItem
    },
    data() {
        return {
            currentStep: 1,
            length: 4,
            packageItem: {
                name: '',
                desc: '',
                bType: "ONE_TIME",
                image: "",
                products: [],
                addOns: [],
                sellingPrice: 0,
                vendorId: ''
            },
            showDialog: false,
            addOnFlag: false,
            suggestedPrice: 0,
            addOns: [],
            products: [],
            image: null,
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.packageItem.vendorId = this.getUserProfile().vendorId
            if (this.id != 0) {
                this.packageItem = (await this.getItem(appConstants.PACKAGES_API + "/" + this.id)).data
            }
        },
        goBack() {
            this.currentStep--
        },
        goNext() {
            this.currentStep++
        },
        async addPackage(packageItem) {
            this.packageItem = packageItem
            this.image = packageItem.uploadImage
            this.goNext()
        },
        removeImage(){
            this.packageItem.image=''
        },
        selectAddOn() {
            this.showDialog = true
            this.addOnFlag = true
        },
        saveProduct(products) {
            if (this.addOnFlag) {
                products.forEach(rec => {
                    if (!this.packageItem.addOns.includes(rec))
                        this.packageItem.addOns.push(rec)
                })
                this.addOnFlag = false
            } else {
                products.forEach(rec => {
                    if (!this.packageItem.products.includes(rec))
                        this.packageItem.products.push(rec)
                })
            }
        },
        removeProduct(product) {
            let index = this.packageItem.products.findIndex(rec => rec._id == product._id)
            if (index > -1)
                this.packageItem.products.splice(index, 1)
            else {
                index = this.packageItem.addOns.findIndex(rec => rec._id == product._id)
                if (index > -1)
                    this.packageItem.addOns.splice(index, 1)
            }
        },
        calculatePrice() {
            this.suggestedPrice = 0
            this.packageItem.products.forEach(rec => {
                this.suggestedPrice += rec.price || rec.sellingPrice
            })
            this.goNext()
        },
        async submit() {
            this.packageItem.products = this.convertToProductRefModel(this.packageItem.products)
            this.packageItem.addOns = this.convertToProductRefModel(this.packageItem.addOns)
            let formData = this.convertToFormData(this.packageItem, [this.image], 'packageImage')
            if (this.packageItem.sellingPrice == 0)
                this.packageItem.sellingPrice = this.suggestedPrice
            if (this.id == 0)
                this.saveItem(formData)
            else
                this.updateItem(formData)
            this.$router.push("/app/packages")
        },
        async saveItem(formData) {
            try {
                await this.postItem(appConstants.PACKAGES_API, formData)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
                this.loading = false
            }
        },
        async updateItem(formData) {
            try {
                await this.putItem(appConstants.PACKAGES_API + "/" + this.packageItem._id, formData)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
                this.loading = false
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.display-block {
    padding: 5% 15%;
}

.heading {
    padding: 2% 0%;
}

.align-center {
    text-align: center
}
</style>
